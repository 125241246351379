@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: "bovaNova";
  src: url("/public/BonaNova-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: "Kanit", sans-serif;
}

.bovaFont {
  font-family: "Bova Nova", sans-serif;
}

.customBG {
  background-color: rgba(0, 0, 0, 0.8);
}

.mobileNavbarBG {
  background-color: rgba(0, 0, 0, 0.95);
}

@media screen and (min-width: 800px) and (max-width: 1000px) and (orientation: landscape) {
  .mobileNavbarCloseIconSize {
    font-size: 2.5rem !important;
  }

  .mobileNavbarTextSize {
    font-size: 1.5rem !important;
  }

  .aboutImage {
    height: 65vh !important;
  }

  .topContactIcons {
    font-size: 0.85em !important;
  }

  .pagebottom {
    padding-bottom: 4rem !important;
  }

  .homeSectionSize {
    min-height: 87vh !important;
  }
}

@media screen and (min-width: 400px) and (max-width: 1000px) and (orientation: landscape) {
  .logoPosition {
    top: 50%;
    left: 50%;
    position: absolute !important;
    transform: translate(-50%, -50%) !important;
  }
  .logoSize {
    height: 2.5rem !important;
  }

  .hamburgerMenuIconSize {
    font-size: 2.2rem !important;
  }

  .cols {
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
  }

  .carouselImage {
    height: 70vh !important;
  }

  .navbarHeight {
    height: auto !important;
    padding: 1rem 0;
  }

  .landscapeMobilNavbar {
    flex-direction: row !important;
  }

  .mobileNavbarTextSize {
    font-size: 1.3rem !important;
    gap: 2;
  }
}
